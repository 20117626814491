.contacts {
  font-size: 2.4rem;

  address {
    font-weight: 300;
    margin-bottom: 2.5rem;
  }

  ul {
    margin-bottom: 2.5rem;
  }
}