@media all and (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-10 {
    width: 80%;
  }
}

@media all and (min-width: 768px) {
  .col-sm-6 {
    width: 48%;
  }
  .col-sm-6-cl {
    width: 50%;
  }
  .col-sm-3 {
    width: 24%;
  }
  .col-sm-4 {
    width: 32%;
  }
}

@media all and (min-width: 992px) {
  .col-md-4 {
    width: 32%;
  }
  .col-md-7 {
    width: 60%;
  }
  .col-md-8 {
    width: 65%;
  }
}