.masters {
  font-size: 15px;
  padding-bottom: 0;

  .sm-title {
    margin: 2rem 0 0;
  }

  .col-md-4 {
    margin: 0 auto 9rem;

    li {
      display: list-item;
      margin-left: 20px;
    }

    ul {
      margin-bottom: 2.5rem;
    }
  }

  img {
    display: block;
    margin: 0 auto 20px;
  }

  span {
    color: $grey;
    font-size: 12px;
    display: inline-block;
    position: relative;
    margin-bottom: 5rem;

    &:after {
      position: absolute;
      content: '';
      width: 50px;
      height: 2px;
      background-color: $green;
      bottom: -3rem;
      left: 0;
    }
  }
}

@media all and (max-width: 767px) {
  .masters {
    .col-md-4 {
      margin-bottom: 5rem;
    }
  }
}