.previous-marathon {
  li {
    position: relative;
    overflow: hidden;
    margin: 10px 0;
  }
}

.cities {
  min-height: 100%;
}

.other-cities {
  list-style: none;

  li {
    height: 250px;
    position: relative;
    display: flex;
    background: url(../images/header-bg.jpg) 50% 100% no-repeat;
    background-size: cover;
    margin-bottom: 20px;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s all;
    }

    & > * {
      display: flex;
      flex: 1;
      margin: 0;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      text-decoration: none;
      padding: 10px;
      font-size: 2.5rem;
      font-weight: normal;
      text-transform: uppercase;
    }

    a {
      &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media all and (max-width: 767px) {
  .other-cities {
    justify-content: center;

    li {
      height: 130px;
    }
  }
}