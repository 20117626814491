.program {
  .simple-title {
    margin-top: 6rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.schedule {
  list-style: none;

  ul.lector {
    padding-left: 20px;
  }

  & > li {
    border-left: 2px solid $light-grey;
    position: relative;
    padding: 0 0 3.5rem 2rem;

    &:before, &:after {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute;
    }

    &:after {
      top: -6px;
      left: -13px;
      background-color: $white;
      width: 24px;
      height: 24px;
    }

    &:before {
      top: -2px;
      left: -9px;
      background-color: $green;
      width: 16px;
      height: 16px;
      z-index: 1;
    }

    &:last-child {
      border-color: transparent;
      padding-bottom: 0;
    }
  }

  .time {
    font-size: 1.8rem;
    font-weight: 500;
    color: $green;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    top: -6px;
  }

  .lector {
    color: $grey;
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media all and (max-width: 991px) {
  .schedule {
    & > li {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media all and (min-width: 992px) {
  .schedule {
    & > li {
      width: 75%;
      margin-left: 25%;
    }

    .time {
      position: absolute;
      left: -130px;
      top: -5px;
    }

    .sm-title {
      position: relative;
      top: -10px;
      margin-bottom: 10px;
    }
  }
}